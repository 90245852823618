











































import Vue from "vue";
import { groupAdd, groupUpdate, groupDetails } from "@/api/user/group";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo"],
  data() {
    return {
      editFormInfo: {
        name: null, //组名
      },
      editFormInfoRules: {
        name: [{ required: true, message: "请输入组名" }],
      },
    };
  },
  mounted() {
    if (this.baseEditInfo.id) {
      groupDetails(this.baseEditInfo.id).then((res) => {
        this.editFormInfo = this.$deepCopy(res.content);
      });
    }
  },
  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      if (obj.id) {
        groupUpdate(obj.id, obj).then((res: any) => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      } else {
        groupAdd(obj).then((res: any) => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      }
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
